import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import CaseStudies from "../views/CaseStudies.vue";
import WorkWithUs from "../views/WorkWithUs.vue";
import GetPremium from "../views/GetPremium.vue";
import NotFound from "../views/NotFound.vue";
import Waitlist from "../views/Waitlist.vue";

var meta = {
  home: {
    title: "Innovative Visuals for Blockchain Data | Bubblemaps",
    description:
      "Explore DeFi tokens and NFTs through bubbles in our crypto dApp. Cryptocurrency data for beginners, degens, and experts.",
  },
  casestudies: {
    title: "Case Studies | See how Bubblemaps enhances DYOR",
    description:
      "Check-out those scenarios where Bubblemaps made a difference. Identify wash trading, DAO, scam tokens, and more!",
  },
  workwithus: {
    title: "Work with Us | Let's Make Crypto Safer, Together",
    description:
      "From DeFi tokens to VCs or marketing agencies, Bubblemaps Pro meets your needs.",
  },
  getpremium: {
    title: "Bubblemaps | Get premium to access our unique crypto bubbles",
    description:
      "Unlimited access, comprehensive features, infinite possibilities.",
  },
  waitlist: {
    title: "Bubblemaps | Join our V2 waitlist",
    description:
      "Explore wallets, reveal connections, and see through the noise of blockchain data.",
  },
  notfound: {
    title: "Bubblemaps | 404",
    description: "Page not found.",
  },
};

for (var page in meta) {
  meta[page].metaTags = [
    {
      property: "og:title",
      content: meta[page].title,
    },
    {
      property: "twitter:title",
      content: meta[page].title,
    },
    {
      name: "description",
      content: meta[page].description,
    },
    {
      property: "og:description",
      content: meta[page].description,
    },
    {
      property: "twitter:description",
      content: meta[page].description,
    },
  ];
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: meta.home,
  },
  {
    path: "/case-studies",
    name: "Case Studies",
    component: CaseStudies,
    meta: meta.casestudies,
  },
  {
    path: "/work-with-us",
    name: "Work With Us",
    component: WorkWithUs,
    meta: meta.workwithus,
  },
  {
    path: "/get-premium",
    name: "Get Premium",
    component: GetPremium,
    meta: meta.getpremium,
  },
  {
    path: "/waitlist",
    name: "Waitlist",
    component: Waitlist,
    meta: meta.waitlist,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Not Found",
    component: NotFound,
    meta: meta.notfound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to) => {
  document.title = to.meta.title;
  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Turn the meta tag definitions into actual elements in the head.
  to.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");
      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));
});

export default router;
