<template>
  <Tweet
    tweet_link="https://x.com/etherscan/status/1811016547756036359"
    image_name="etherscan.png"
    name="Etherscan"
    handle="@etherscan"
    is_verified
  >
    Learn about a token’s Decentralization Score and its identified supply in
    CEX & contracts
    <br /><br />
    Powered by
    <b>@bubblemaps</b>
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
