<template>
  <!-- DIALOGS -->
  <Dialog ref="squigglesDialog" :defaultWidth="1200">
    <div class="mdc-dialog__content">
      <h3>
        Select a wallet then <i>Show Transfers</i> to see how much ETH was sent
        back and forth!
      </h3>
      <iframe
        src="https://app.bubblemaps.io/eth/token/0xba07cd4712a308be5f117292a07beff94a7fe0cf?id=KdhdkgCNt8m68P5DlZW4&small_text"
        class="noselect"
      />
    </div>
    <div class="mdc-dialog__actions">
      <Button data-mdc-dialog-action="discard" data-mdc-dialog-initial-focus>
        Close
      </Button>
    </div>
  </Dialog>
  <Dialog ref="uniswapDialog" :defaultWidth="1200">
    <div class="mdc-dialog__content">
      <h3>Click on a bubble to check the delegation!</h3>
      <iframe
        src="https://app.bubblemaps.io/eth/token/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984?mode=3&id=Db2VHvwX3qCbIMOZSs3b&small_text"
        class="noselect"
      />
    </div>
    <div class="mdc-dialog__actions">
      <Button data-mdc-dialog-action="discard" data-mdc-dialog-initial-focus>
        Close
      </Button>
    </div>
  </Dialog>
  <Dialog ref="shibDialog" :defaultWidth="1200">
    <div class="mdc-dialog__content">
      <h3>
        Check the historical data to follow the founder’s wallets overtime!
      </h3>
      <iframe
        src="https://app.bubblemaps.io/eth/token/0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce?mode=3&id=p9vAV6Fem2ep9INZr5on&small_text"
        class="noselect"
      />
    </div>
    <div class="mdc-dialog__actions">
      <Button data-mdc-dialog-action="discard" data-mdc-dialog-initial-focus>
        Close
      </Button>
    </div>
  </Dialog>
  <!-- CONTENT -->
  <div class="header">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 title sr_prevent_flickering"
          v-scrollreveal
        >
          <h1>Case Studies</h1>
          <h4>
            Learn how to use Bubblemaps when reading on&#8209;chain&nbsp;data.
            <br /><b>A clear vision for better&nbsp;decisions.</b>
          </h4>
        </div>
      </div>
    </div>
  </div>
  <div class="casestudies">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner casestudies__studies">
        <!-- UNISWAP -->

        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-5-desktop"
        >
          <div class="casestudies__token">
            <img src="@/assets/logos/uniswap.jpeg" alt="Uniswap logo" />
            <h4>Uniswap</h4>
          </div>
          <h3>The Illusion of Democracy</h3>
          <p>
            The Uniswap Protocol operates using the governance token UNI. UNI
            holders have the ability to vote on future development proposals for
            the protocol.
          </p>
          <p>
            We have identified that Andreessen Horowitz (a16z), a large private
            investor, owns a significant portion of the supply through 11
            connected wallets. Because they hold more than 4%, which is known as
            the Uniswap quorum, they could pass proposals on their own. This
            raises concerns about a shift from democracy to oligarchy!
          </p>
        </div>

        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-7-desktop casestudies__preview-container sr_prevent_flickering noselect"
          v-scrollreveal="{ delay: 200 }"
          @click="$refs.uniswapDialog.open()"
        >
          <img
            src="@/assets/screenshots/uniswap.png"
            class="casestudies__preview"
            alt="Uniswap bubble map preview"
          />
          <div class="casestudies__tryme">Try me!</div>
        </div>

        <!-- SHIB -->

        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-5-desktop casestudies__odd--left"
        >
          <div class="casestudies__token">
            <img src="@/assets/logos/shiba.jpeg" alt="Shib logo" />
            <h4>Shib</h4>
          </div>
          <h3>The Hidden Founder of Shiba Inu</h3>
          <p>
            Shiba Inu has become the largest meme coin in the cryptocurrency
            industry, with a market cap in the billions.
          </p>
          <p>
            A group of interconnected wallets, which collectively hold over 10%
            of the coin's supply, has been identified. In 2020, one of these
            wallets purchased SHIB tokens worth 10k, representing 10% of the
            total supply. Later, the owner of this wallet decided to split the
            holdings into this cluster for increased security and anonymity.
            These wallets are likely owned by the founder of SHIB.
          </p>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-7-desktop casestudies__preview-container sr_prevent_flickering noselect"
          v-scrollreveal="{ delay: 200 }"
          @click="$refs.shibDialog.open()"
        >
          <img
            src="@/assets/screenshots/shib.png"
            class="casestudies__preview"
            alt="Shib bubble map preview"
          />
          <div class="casestudies__tryme">Try me!</div>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-5-desktop casestudies__odd--right"
        >
          <div class="casestudies__token">
            <h4>Shib</h4>
            <img src="@/assets/logos/shiba.jpeg" alt="Shib logo" />
          </div>
          <h3>The Hidden Founder of Shiba Inu</h3>
          <p>
            Shiba Inu has become the largest meme coin in the cryptocurrency
            industry, with a market cap in the billions.
          </p>
          <p>
            A group of interconnected wallets, which collectively hold over 10%
            of the coin's supply, has been identified. In 2020, one of these
            wallets purchased SHIB tokens worth 10k, representing 10% of the
            total supply. Later, the owner of this wallet decided to split the
            holdings into this cluster for increased security and anonymity.
            These wallets are likely owned by the founder of SHIB.
          </p>
        </div>

        <!-- SQUIGGLES -->

        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-5-desktop"
        >
          <div class="casestudies__token">
            <img src="@/assets/logos/squiggles.png" alt="Squiggles logo" />
            <h4>Squiggles</h4>
          </div>
          <h3>
            How a notorious NFT collection used wash trading to inflate the
            trading volume and floor price
          </h3>
          <p>
            Squiggles is an NFT collection on Ethereum. On February 10 2021, the
            blockchain detective
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=PbBft8SPpCA"
              >CoffeeZilla</a
            >
            reported that Squiggles was using shadow wallets to virtually
            increase the trading volume.
          </p>
          <p>
            Using Bubblemaps, we can see that 3 central wallets are circulating
            ETH to 25 wallets. This pattern is a typical sign of wash trading,
            where an investor simultaneously sells and buys an asset to create
            hype around the project.
          </p>
        </div>

        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-7-desktop casestudies__preview-container sr_prevent_flickering noselect"
          v-scrollreveal="{ delay: 200 }"
          @click="$refs.squigglesDialog.open()"
        >
          <img
            src="@/assets/screenshots/squiggles.png"
            class="casestudies__preview"
            alt="Squiggles bubble map preview"
          />
          <div class="casestudies__tryme">Try me!</div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="morestudies">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner morestudies__title">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <h2>More Case Studies</h2>
        </div>
      </div>
      <div class="mdc-layout-grid__inner morestudies__studies">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
          <a
            class="morestudies__thumbnail"
            href="https://twitter.com/bubblemaps/status/1714672882511712755"
            target="_blank"
          >
            <div class="morestudies__logo-container">
              <img src="@/assets/logos_white/dydx.png" alt="dYdX logo" />
            </div>
            <h3>dYdX</h3>
          </a>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
          <a
            class="morestudies__thumbnail"
            href="https://twitter.com/bubblemaps/status/1684610663090724864"
            target="_blank"
          >
            <div class="morestudies__logo-container">
              <img src="@/assets/logos_white/unibot.png" alt="Unibot logo" />
            </div>
            <h3>Unibot</h3>
          </a>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
          <a
            class="morestudies__thumbnail"
            href="https://twitter.com/bubblemaps/status/1704888268037505412"
            target="_blank"
          >
            <div class="morestudies__logo-container">
              <img src="@/assets/logos_white/gala.png" alt="GALA logo" />
            </div>
            <h3>GALA</h3>
          </a>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
          <a
            class="morestudies__thumbnail"
            href="https://twitter.com/bubblemaps/status/1723053393210744863"
            target="_blank"
          >
            <div class="morestudies__logo-container">
              <img src="@/assets/logos_white/grok.png" alt="GROK logo" />
            </div>
            <h3>GROK</h3>
          </a>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
          <a
            class="morestudies__thumbnail"
            href="https://twitter.com/bubblemaps/status/1655256269660225536"
            target="_blank"
          >
            <div class="morestudies__logo-container">
              <img src="@/assets/logos_white/pepe.png" alt="PEPE logo" />
            </div>
            <h3>PEPE</h3>
          </a>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
          <a
            class="morestudies__thumbnail"
            href="https://twitter.com/bubblemaps/status/1615032502753853441"
            target="_blank"
          >
            <div class="morestudies__logo-container">
              <img src="@/assets/logos_white/chiliz.png" alt="chiliz logo" />
            </div>
            <h3>chiliz</h3>
          </a>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import Dialog from "@/components/atoms/material/Dialog.vue";
import Button from "@/components/atoms/material/Button.vue";

export default {
  name: "CaseStudies",
  components: {
    Dialog,
    Button,
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.header {
  @include theme-almost-black-gradient(45deg);
  margin-top: -56px;
  padding-top: 56px;
}

@media (min-width: $mdc-tablet) {
  .header {
    margin-top: -64px;
    padding-top: 64px;
  }
}

.title {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;

  h1 {
    margin-top: 0;
    margin-bottom: 1rem;
    @include scaling-title(2.1rem, 400px, 200px);
  }
}

@media (max-width: $mdc-tablet) {
  .title {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.casestudies {
  padding-top: 50px;
  padding-bottom: 50px;
  background: url("../assets/backgrounds/blur.png") no-repeat center;
  background-size: cover;

  p {
    font-weight: 300;
  }

  .casestudies__studies {
    margin-bottom: 50px;
    max-width: 1100px;
    margin: auto;
  }

  .casestudies__token {
    display: flex;
    align-items: center;

    img {
      height: 40px;
      width: 40px;
      border-radius: 100px;
      margin-right: 10px;
    }

    h4 {
      margin: 0;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }
  }

  .casestudies__preview {
    width: 100%;
    height: 100%;
    min-height: 300px;
    border-radius: 10px;
    object-fit: cover;
    position: relative;
    transition: transform 200ms ease-in-out;
  }

  .casestudies__tryme {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 4px;
    font-weight: 700;
    font-size: 1.2rem;
    background-color: rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 0 0 10px 10px;
    color: rgba(255, 255, 255, 0.8);
    transition: color 200ms ease-in-out, transform 200ms ease-in-out;
  }

  .casestudies__preview-container {
    position: relative;
    cursor: pointer;

    &:hover {
      .casestudies__tryme,
      .casestudies__preview {
        color: $theme-blue;
        transform: translateY(-10px);
      }
    }
  }

  .casestudies__odd--right {
    display: none;
    text-align: right;

    .casestudies__token {
      justify-content: flex-end;

      img {
        margin-right: 0px;
        margin-left: 10px;
      }
    }
  }
}

.mdc-dialog__content {
  iframe {
    width: 100%;
    height: 350px;
    border: none;
    border-radius: 10px;
    background-color: $theme-almost-black;
  }
}

@media (min-width: $mdc-grid-tablet) {
  .mdc-dialog__content {
    iframe {
      height: 400px;
    }
  }
}

@media (min-width: $mdc-grid-desktop) {
  .casestudies {
    .casestudies__odd--left {
      display: none;
    }
    .casestudies__odd--right {
      display: block;
    }
    iframe {
      margin-bottom: 20px;
    }
    .casestudies__studies {
      row-gap: 80px;
    }
  }

  .mdc-dialog__content {
    iframe {
      height: 500px;
    }
  }
}

.morestudies {
  padding-top: 50px;
  padding-bottom: 50px;
  background: url("../assets/backgrounds/blur.png") no-repeat center;
  background-size: cover;

  .morestudies__title {
    text-align: center;
  }

  .morestudies__studies {
    max-width: 1100px;
    margin: auto;
  }

  .morestudies__thumbnail {
    @include theme-glass-effect;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      height: 75px;
      z-index: 2;
      position: relative;
    }

    h3 {
      margin-bottom: 0;
      margin-top: 15px;
      font-size: 1.5rem;
      font-weight: 300;
      letter-spacing: 0.2rem;
    }

    .morestudies__logo-container {
      padding: 15px 0;
      width: 120px;
      text-align: center;
      position: relative;
      transition: transform 200ms ease-in-out;
    }

    .morestudies__logo-container:before {
      @include theme-blue-gradient(45deg);
      content: "";
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      border-radius: 15px;
      /* TRANSISITION */
      transition: opacity 200ms ease-in-out;
      -webkit-transition: opacity 200ms ease-in-out;
      -moz-transition: opacity 200ms ease-in-out;
      -o-transition: opacity 200ms ease-in-out;
    }

    &:hover {
      .morestudies__logo-container:before {
        opacity: 1;
      }
      .morestudies__logo-container {
        transform: translateY(-10px);
      }
    }
  }
}
</style>
