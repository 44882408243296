<template>
  <div class="trustedby">
    <h2>Backed by</h2>
    <div class="trustedby__logos">
      <img alt="Consensys logo" src="@/assets/logos/backers/consensys.png" />
      <img
        class="--ince"
        alt="Ince logo"
        src="@/assets/logos/backers/ince.png"
      />
      <img alt="Momentum6 logo" src="@/assets/logos/backers/momentum.png" />
      <img alt="V3ntures logo" src="@/assets/logos/backers/v3ntures.png" />
      <img alt="Polygon logo" src="@/assets/logos/backers/polygon.png" />
      <img alt="Avalanche logo" src="@/assets/logos/backers/avalanche.png" />
      <img alt="Linea logo" src="@/assets/logos/backers/linea.png" />
      <img alt="Aptos logo" src="@/assets/logos/backers/aptos.png" />
      <img alt="Fuel logo" src="@/assets/logos/backers/fuel.png" />
      <img alt="Ledger logo" src="@/assets/logos/backers/ledger.png" />
      <img alt="Sandbox logo" src="@/assets/logos/backers/sandbox.png" />
    </div>
    <h4>and many more...</h4>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/variables";

.trustedby {
  text-align: center;

  h4 {
    opacity: 0.9;
  }

  .trustedby__button {
    margin-top: 15px;
  }

  .trustedby__logos {
    text-align: center; /* Center the inline elements */
    box-sizing: border-box; /* Ensure padding doesn't affect total width */
    width: 1000px;
    margin: auto;
    margin-bottom: 10px;
  }
  img {
    opacity: 0.8;
    height: 35px; /* Set a uniform height for all logos */
    width: auto; /* Maintain aspect ratio */
    display: inline-block; /* Allow images to sit next to each other */
    margin: 10px; /* Optional: space between logos */
    vertical-align: middle; /* Align images vertically */

    &.--ince {
      height: 45px;
      margin: 0 10px;
    }
  }
}

@media (max-width: 1130px) {
  .trustedby {
    .trustedby__logos {
      width: 700px;
    }
  }
}

@media (max-width: 760px) {
  .trustedby {
    .trustedby__logos {
      width: 400px;
    }
  }
}
</style>
