<template>
  <Tweet
    tweet_link="https://x.com/pumpdotfun/status/1796219328066396509"
    image_name="pumpfun.png"
    name="pump.fun"
    handle="@pumpdotfun"
    is_verified
    is_gold
  >
    finding it difficult to sus out shitty devs? onchain sleuthing taking too
    long?
    <br /><br />
    now, it's 10x easier
    <br /><br />
    in partnership with
    <b>@bubblemaps</b>
    , anyone can generate a bubble map chart which shows flows between wallets
    that interacted with the coin you're interested in
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
