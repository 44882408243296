<template>
  <div class="header">
    <div class="header__text-container">
      <div>
        <h1>Take Up the Habit,<br />Just Bubble&nbsp;it.</h1>
        <h3>Innovative Visuals for Blockchain&nbsp;Data</h3>
      </div>
      <div>
        <Button
          outlined
          class="header__button"
          link="https://app.bubblemaps.io"
          target="_blank"
        >
          Launch App
        </Button>
        <Button raised class="header__button" route="/waitlist">
          V2 Waitlist
        </Button>
      </div>
    </div>
    <div v-for="i in []" :key="i" :class="`floating_bubble --${i}`">
      <div class="bubble">
        <!-- {{ i }} -->
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/material/Button.vue";

export default {
  name: "Header",
  components: {
    Button,
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.header {
  text-align: center;
  min-height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .header__text-container {
    height: 350px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;

    h1 {
      margin-top: 0;
      margin-bottom: 1rem;
      @include scaling-title(2.5rem, 540px, 400px);
    }

    h3 {
      font-size: 1.3rem;
      font-weight: normal;
      opacity: 0.7;
      margin-top: 0;
      margin-bottom: 2rem;
    }

    .header__button {
      margin: 5px;
    }
  }

  @media (max-width: $mdc-tablet) {
    .header__text-container {
      height: 300px;
    }
  }
}

@media (min-width: $mdc-tablet) {
  .header {
    min-height: calc(100vh - 64px);
  }

  @media (min-height: 1000px) {
    .header {
      min-height: calc(1000px - 64px);
    }
  }
}

@media (min-width: 1230px) {
  .header {
    button {
      margin-left: 0 !important;
      margin-right: 10px !important;
    }
  }
}

@media (min-width: 1400px) {
  .header {
    .header__text-container {
      h1 {
        font-size: 3rem;
      }
    }
  }
}

.floating_bubble {
  position: absolute;
  animation: float 1.5s infinite alternate ease-in-out;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  .bubble {
    border-radius: 50%;
    background: radial-gradient(
      78.06% 77.71% at 65.31% 61.34%,
      #03060e 0%,
      #0d152b 59.38%,
      #192444 100%
    );
    border: 3px solid #4d5773;
    transform: scale(0.5);

    @media (min-width: 520px) {
      transform: scale(0.6);
    }

    @media (min-width: 700px) {
      transform: scale(0.7);
    }

    @media (min-width: 850px) {
      transform: scale(0.8);
    }

    @media (min-width: 1000px) {
      transform: scale(1);
    }
  }

  &.--1 {
    top: 3%;
    left: 0;
    filter: blur(4px);
    z-index: -4;
    animation-delay: 0.5s;

    .bubble {
      height: 200px;
      width: 200px;
      transform-origin: top left;
    }
  }

  &.--2 {
    top: 5%;
    right: 0;
    filter: blur(0px);
    z-index: 0;

    .bubble {
      height: 150px;
      width: 150px;
      transform-origin: top right;
    }
  }

  &.--3 {
    bottom: 20%;
    left: 10%;
    filter: blur(0px);
    z-index: 0;
    animation-delay: 1s;

    .bubble {
      height: 120px;
      width: 120px;
      transform-origin: bottom left;
    }
  }

  &.--4 {
    top: 65%;
    right: 15%;
    filter: blur(1px);
    z-index: -1;

    .bubble {
      height: 140px;
      width: 140px;
      transform-origin: top right;
    }
  }

  &.--5 {
    top: 54%;
    right: 10%;
    filter: blur(4px);
    z-index: -4;
    animation-delay: 0.7s;

    .bubble {
      height: 100px;
      width: 100px;
      transform-origin: top right;
    }
  }

  &.--6 {
    top: 28%;
    left: 40%;
    filter: blur(4px);
    z-index: -4;
    animation-delay: 0.5s;

    .bubble {
      height: 150px;
      width: 150px;
      transform-origin: top left;
    }
  }

  &.--7 {
    bottom: 30%;
    left: 32%;
    filter: blur(4px);
    z-index: -4;

    .bubble {
      height: 120px;
      width: 120px;
      transform-origin: bottom left;
    }
  }

  &.--8 {
    top: 15%;
    left: 18%;
    filter: blur(1px);
    z-index: -1;
    animation-delay: 0.8s;

    .bubble {
      height: 100px;
      width: 100px;
      transform-origin: top left;
    }
  }

  &.--9 {
    top: 10%;
    right: 25%;
    filter: blur(1px);
    z-index: -1;
    animation-delay: 1.2s;

    .bubble {
      height: 120px;
      width: 120px;
      transform-origin: top right;
    }
  }

  &.--10 {
    bottom: 7%;
    left: 25%;
    filter: blur(1px);
    z-index: -1;
    animation-delay: 1.2s;

    .bubble {
      height: 80px;
      width: 80px;
      transform-origin: bottom left;
    }
  }

  &.--11 {
    bottom: calc(50% - 300px);
    left: calc(50% - 300px);
    filter: blur(1px);
    z-index: -1;
    animation-delay: 1.2s;
    opacity: 0.6;

    .bubble {
      height: 600px;
      width: 600px;
      transform-origin: bottom left;
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(-3px);
  }
}
</style>
