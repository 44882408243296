<template>
  <Tweet
    tweet_link="https://twitter.com/solana/status/1788951319094669617"
    image_name="solana.jpeg"
    name="Solana"
    handle="@solana"
    is_verified
    is_gold
  >
    Data analytics and visualization support for Solana continues to expand —
    <b>@bubblemaps</b>
    has integrated Solana!&nbsp;🫧
    <br /><br />
    Start exploring Solana token data and share your creations!
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
