<template>
  <Tweet
    tweet_link="https://x.com/TrojanOnSolana/status/1812900622703947979"
    image_name="trojan.jpg"
    name="Trojan"
    handle="@TrojanOnSolana"
    is_verified
    is_gold
  >
    Bubblemaps live on Trojan.
    <br /><br />
    The tools you need to gain an edge, all in one place. Quickly access the
    distribution of any token before you start trading, only on Trojan.
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
