<template>
  <div class="home__header">
    <div class="home__header-overlay"></div>
    <!-- <img
      alt=""
      src="@/assets/motifs/motif1.svg"
      class="home__motif --1 noselect"
    />
    <img
      alt=""
      src="@/assets/clusters/cluster1.svg"
      class="home__motif --2 noselect"
    />
    <img
      alt=""
      src="@/assets/clusters/cluster2.svg"
      class="home__motif --3 noselect"
    />
    <img
      alt=""
      src="@/assets/motifs/motif3.svg"
      class="home__motif --4 noselect"
    /> -->
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <Header />
        </div>
      </div>
    </div>
  </div>
  <div class="home__strip">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 home__section"
        >
          <TrustedBy />
        </div>
      </div>
    </div>
  </div>
  <div class="home_middle">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner home__section">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <AppIntro />
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <Partners />
        </div>
      </div>
    </div>
  </div>
  <div class="home__bottom">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 home__section home__testimonials"
        >
          <Testimonials />
        </div>
      </div>
      <div class="home__section home__form">
        <SubscribeForm />
      </div>
    </div>
  </div>
</template>

<script>
import SubscribeForm from "@/components/molecules/SubscribeForm.vue";
import Testimonials from "@/components/organisms/Testimonials/Testimonials.vue";
import TrustedBy from "@/components/molecules/TrustedBy.vue";
import AppIntro from "@/components/molecules/AppIntro.vue";
import Partners from "@/components/molecules/Partners.vue";
import Header from "@/components/organisms/Header/Header.vue";

export default {
  name: "Navigation",
  components: {
    SubscribeForm,
    Testimonials,
    TrustedBy,
    AppIntro,
    Header,
    Partners,
  },
  methods: {
    openVideo() {
      this.$refs.videoDialog.open();
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

.home__section {
  padding: 60px 0;
}

.home__header {
  background: url("../assets/backgrounds/wave.jpg") no-repeat center;
  background-size: cover;
  margin-top: -56px;
  padding-top: 56px;
  position: relative;
  overflow: hidden;

  .mdc-layout-grid {
    position: relative;
    z-index: 1;
  }
}

@media (min-width: $mdc-tablet) {
  .home__header {
    margin-top: -64px;
    padding-top: 64px;
  }
}

.home__bottom {
  background: url("../assets/backgrounds/blur.png") no-repeat center;
  background-size: cover;
}

.home__motif {
  position: absolute;
  opacity: 0.6;
  z-index: 0;

  &.--1 {
    bottom: -86px;
    right: -993px;
    height: 719px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    mask-image: linear-gradient(16deg, rgba(0, 0, 0, 1) 50%, transparent);
  }

  &.--2 {
    height: 102px;
    bottom: 586px;
    right: -40px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    mask-image: linear-gradient(16deg, rgba(0, 0, 0, 1) 50%, transparent);
  }

  &.--3 {
    height: 100px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    top: 130px;
    mask-image: linear-gradient(311deg, rgb(0, 0, 0), #000000a6);
  }

  &.--4 {
    height: 533px;
    bottom: -354px;
    left: -133px;
    transform: rotate(1deg) scaleX(-1);
    mask-image: linear-gradient(24deg, rgba(0, 0, 0, 1) 50%, transparent);
  }
}

@media (max-width: 1229px) {
  .home__motif {
    &.--1 {
      bottom: -100px;
      left: -450px;
      height: 400px;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      right: auto;
    }

    &.--2 {
      bottom: 0;
      left: 87px;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      right: auto;
    }

    &.--3 {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      right: 0;
    }

    &.--4 {
      display: none;
    }
  }
}

@media (max-width: $mdc-grid-tablet) {
  .home__motif {
    &.--1 {
      bottom: -65px;
      left: -354px;
      height: 300px;
    }

    &.--2 {
      height: 75px;
      bottom: 10px;
      left: 51px;
    }

    &.--3 {
      right: 0;
    }
  }
}

.home__testimonials {
  padding-bottom: 0;
}

.home__form {
  max-width: 1000px;
  margin: auto;
}

.home__strip {
  background-color: $theme-almost-black;
}

.home_middle {
  background: url("../assets/backgrounds/blur.png") no-repeat center;
  background-size: cover;
}

.home__header-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 0.5;
  mix-blend-mode: color;
  background: radial-gradient(
      100% 100% at var(--15-x-position) var(--15-y-position),
      #872279 0%,
      transparent
    ),
    radial-gradient(
      100% 100% at var(--14-x-position) var(--14-y-position),
      #3062b1 0%,
      transparent
    ),
    radial-gradient(
      100% 100% at var(--16-x-position) var(--16-y-position),
      #3e2188 0%,
      transparent
    ),
    transparent;
  animation-name: main;
  animation-iteration-count: infinite;
  animation-duration: 7s;
  transition-timing-function: ease-in;
}
@property --14-x-position {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 19.8046875%;
}
@property --14-y-position {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 56.56250000000001%;
}
@property --15-x-position {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 82.34375%;
}
@property --15-y-position {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 33.8671875%;
}
@property --16-x-position {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 79.609375%;
}
@property --16-y-position {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 66.9140625%;
}
:root {
  --5-x-position: 20%;
  --5-y-position: 80%;
  --6-x-position: 80%;
  --6-y-position: 20%;
  --7-x-position: 80%;
  --7-y-position: 80%;
  --8-x-position: 20%;
  --8-y-position: 80%;
  --9-x-position: 82.34375%;
  --9-y-position: 33.8671875%;
  --10-x-position: 80%;
  --10-y-position: 80%;
  --11-x-position: 19.8046875%;
  --11-y-position: 56.56250000000001%;
  --12-x-position: 82.34375%;
  --12-y-position: 33.8671875%;
  --13-x-position: 80%;
  --13-y-position: 80%;
  --14-x-position: 19.8046875%;
  --14-y-position: 56.56250000000001%;
  --15-x-position: 82.34375%;
  --15-y-position: 33.8671875%;
  --16-x-position: 79.609375%;
  --16-y-position: 66.9140625%;
}
@keyframes main {
  25% {
    --14-x-position: 15%;
    --14-y-position: 15%;
    --15-x-position: 85%;
    --15-y-position: 80%;
  }
  50% {
    --14-x-position: 80%;
    --14-y-position: 15%;
    --15-x-position: 15%;
    --15-y-position: 85%;
    --16-x-position: 20%;
    --16-y-position: 20%;
  }
}
</style>
