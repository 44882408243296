<template>
  <Tweet
    tweet_link="https://x.com/0xPolygonDeFi/status/1631338731784269824"
    image_name="polygon.jpg"
    name="Polygon DeFi"
    handle="@0xPolygonDeFi"
    is_verified
  >
    Unleash the power of on-chain visuals like never before, now live
    #onPolygon!
    <br /><br />
    Simplify on-chain data analysis and make it playful with
    <b>@bubblemaps</b>
  </Tweet>
</template>

<script>
import Tweet from "@/components/atoms/Tweet.vue";

export default {
  components: {
    Tweet,
  },
};
</script>
