<template>
  <aside ref="mdcDrawer" class="mdc-drawer mdc-drawer--modal">
    <div class="mdc-drawer__content" @click="closeDrawer">
      <List>
        <a
          class="mdc-list-item mdc-list-item--gradient"
          href="https://app.bubblemaps.io/"
          target="_blank"
        >
          <span class="mdc-list-item__ripple"></span>
          <span class="mdc-list-item__text">Launch App</span>
        </a>
        <hr class="mdc-list-divider" />
        <router-link
          class="mdc-list-item"
          to="/"
          active-class="mdc-list-item--activated"
        >
          <span class="mdc-list-item__ripple"></span>
          <span class="mdc-list-item__text">Home</span>
        </router-link>
        <router-link
          class="mdc-list-item"
          to="/waitlist"
          active-class="mdc-list-item--activated"
        >
          <span class="mdc-list-item__ripple"></span>
          <span class="mdc-list-item__text">V2 Waitlist</span>
        </router-link>
        <router-link
          class="mdc-list-item"
          to="/case-studies"
          active-class="mdc-list-item--activated"
        >
          <span class="mdc-list-item__ripple"></span>
          <span class="mdc-list-item__text">Case Studies</span>
        </router-link>
        <router-link
          class="mdc-list-item"
          to="/work-with-us"
          active-class="mdc-list-item--activated"
        >
          <span class="mdc-list-item__ripple"></span>
          <span class="mdc-list-item__text">Work with Us</span>
        </router-link>
        <router-link
          class="mdc-list-item"
          to="/get-premium"
          active-class="mdc-list-item--activated"
        >
          <span class="mdc-list-item__ripple"></span>
          <span class="mdc-list-item__text">Get Premium</span>
        </router-link>
        <a
          class="mdc-list-item"
          href="https://blog.bubblemaps.io/"
          target="_blank"
        >
          <span class="mdc-list-item__ripple"></span>
          <span class="mdc-list-item__text">Blog</span>
        </a>
      </List>
    </div>
  </aside>
  <div class="mdc-drawer-scrim"></div>
  <header class="mdc-top-app-bar mdc-top-app-bar--fixed" ref="mdcTopAppBar">
    <div class="mdc-top-app-bar__row">
      <section
        class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start"
      >
        <router-link class="mdc-top-app-bar__logo-container" to="/">
          <SvgBubblemapsLogo class="mdc-top-app-bar__logo" />
          <SvgBubblemapsText class="mdc-top-app-bar__logotext" />
        </router-link>
      </section>
      <section
        class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end"
        role="toolbar"
      >
        <router-link
          class="mdc-top-app-bar__custom-menu"
          active-class="mdc-top-app-bar__custom-menu--active"
          to="/waitlist"
        >
          <span>V2 Waitlist</span>
        </router-link>
        <router-link
          class="mdc-top-app-bar__custom-menu"
          active-class="mdc-top-app-bar__custom-menu--active"
          to="/case-studies"
        >
          <span>Case Studies</span>
        </router-link>
        <router-link
          class="mdc-top-app-bar__custom-menu"
          active-class="mdc-top-app-bar__custom-menu--active"
          to="/work-with-us"
        >
          <span>Work with Us</span>
        </router-link>
        <router-link
          class="mdc-top-app-bar__custom-menu"
          active-class="mdc-top-app-bar__custom-menu--active"
          to="/get-premium"
        >
          <span>Get Premium</span>
        </router-link>
        <a
          class="mdc-top-app-bar__custom-menu"
          href="https://blog.bubblemaps.io/"
          target="_blank"
        >
          <span>Blog</span>
        </a>
        <Button raised link="https://app.bubblemaps.io/" target="_blank">
          Launch App
        </Button>
        <a
          class="mdc-icon-button mdc-top-app-bar__action-item mdc-top-app-bar__action-item--drawer-toggle"
          @click="openDrawer"
        >
          <SvgBurgerIcon />
        </a>
      </section>
    </div>
  </header>
</template>

<script>
import Button from "@/components/atoms/material/Button.vue";
import List from "@/components/atoms/material/List.vue";
import { MDCDrawer } from "@material/drawer";
import { MDCTopAppBar } from "@material/top-app-bar";

export default {
  name: "Navigation",
  components: {
    Button,
    List,
  },
  data() {
    return {
      drawerComponent: null,
      topAppBarComponent: null,
    };
  },
  methods: {
    openDrawer: function () {
      this.drawerComponent.open = true;
    },
    closeDrawer: function () {
      this.drawerComponent.open = false;
    },
    toggleMenu: function () {
      this.menuComponent.open = !this.menuComponent.open;
      // prevent menu from re-openning when closing it by clicking on the button
      document.body.addEventListener(
        "click",
        (e) => {
          if (this.$refs["mdcMenuButton"].contains(e.target)) {
            e.stopPropagation();
          }
        },
        { capture: true, once: true }
      );
    },
  },
  mounted: function () {
    this.drawerComponent = MDCDrawer.attachTo(this.$refs["mdcDrawer"]);
    this.topAppBarComponent = MDCTopAppBar.attachTo(this.$refs["mdcTopAppBar"]);
  },
  unmounted: function () {
    this.drawerComponent.destroy();
    this.topAppBarComponent.destroy();
  },
};
</script>

<style lang="scss">
@use "sass:map";
@import "src/assets/variables";

.bubblemaps-mdc {
  .mdc-top-app-bar {
    align-items: center;
  }

  .mdc-top-app-bar__row {
    max-width: $mdc-layout-grid-max-width;
    padding: 0 map.get($mdc-layout-grid-default-margin, "phone");
  }

  @media (min-width: $mdc-grid-tablet) {
    .mdc-top-app-bar__row {
      padding: 0 map.get($mdc-layout-grid-default-margin, "tablet");
    }
  }

  @media (min-width: $mdc-grid-desktop) {
    .mdc-top-app-bar__row {
      padding: 0 map.get($mdc-layout-grid-default-margin, "desktop");
    }
  }

  .mdc-top-app-bar__section {
    padding: 8px 0;
  }

  .mdc-top-app-bar__logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  /* Drawer Button and titles conditionnal display */

  .mdc-top-app-bar__custom-menu {
    display: none;
  }
  .mdc-top-app-bar .mdc-button {
    display: none;
  }

  @media (min-width: $theme-drawer-width-threshold) {
    .mdc-top-app-bar__action-item--drawer-toggle {
      display: none;
    }

    .mdc-top-app-bar .mdc-button,
    .mdc-top-app-bar__custom-menu {
      display: initial;
      margin-left: 20px;
    }
  }

  .mdc-top-app-bar__logo {
    height: 23px;
  }

  .mdc-top-app-bar__logotext {
    height: 17px;
    fill: white;
    margin-left: 9px;
    position: relative;
    top: 2px;
  }

  @media (min-width: $mdc-tablet) {
    .mdc-top-app-bar__logo {
      height: 30px;
    }

    .mdc-top-app-bar__logotext {
      height: 20px;
    }
  }

  @media (max-width: 890px) {
    .mdc-top-app-bar {
      font-size: 0.9rem;
    }
  }

  .mdc-drawer--modal + .mdc-drawer-scrim {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .mdc-drawer {
    border: none;
    background-color: $theme-almost-black;

    .mdc-list-item--gradient {
      @include theme-pink-gradient(45deg);
    }

    .mdc-list-item {
      margin: 8px;
      height: 36px;
      border-radius: 18px;
      display: flex;
      align-items: center;
    }
  }

  .mdc-top-app-bar {
    white-space: nowrap;
    background-color: transparent;
    transition: box-shadow 200ms linear;
  }

  .mdc-top-app-bar:after {
    background: url("../../../assets/backgrounds/blur.png") no-repeat center;
    background-size: cover;
    content: "";
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* TRANSISITION */
    transition: opacity 200ms ease-in-out;
    -webkit-transition: opacity 200ms ease-in-out;
    -moz-transition: opacity 200ms ease-in-out;
    -o-transition: opacity 200ms ease-in-out;
  }

  .mdc-top-app-bar--fixed-scrolled:after {
    opacity: 1;
  }

  .mdc-top-app-bar__custom-menu {
    transition: color 150ms ease-in-out;
    &:hover {
      color: $theme-blue;
    }
    &.mdc-top-app-bar__custom-menu--active {
      color: $theme-pink;
    }
  }
}
</style>
