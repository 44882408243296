<template>
  <div class="waitlist">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12 waitlist__form"
        >
          <form @submit.prevent="onSubmit" class="waitlist-form">
            <label for="email" class="waitlist-form__top">
              <div class="waitlist-form__title">Join our V2 Beta Waitlist</div>
              <div class="waitlist-form__subtitle">
                No need to spam, the selection is only time based
                (<b>not</b>&nbsp;randomized).
              </div>
              <div class="waitlist-form__subtitle-2">
                Avoid hotmail and outlook addresses, we've had some complaints
                about emails not getting delievered to these.
              </div>
            </label>
            <div class="waitlist-form__bottom">
              <div class="waitlist-form__content">
                <input
                  type="email"
                  autocapitalize="off"
                  autocorrect="off"
                  v-model="email"
                  name="email"
                  required
                  placeholder="Enter your email address"
                  class="waitlist-form__input"
                />
                <button type="submit" class="waitlist-form__button">
                  <SvgRightArrowIcon v-if="!loading" />
                  <SvgLoadingIcon v-else />
                </button>
              </div>
              <div v-if="error" class="waitlist-form__error">{{ error }}</div>
              <div v-if="success" class="waitlist-form__success">
                Waitlist successfully joined!
              </div>
              <div v-if="success" class="waitlist-form__subtitle">
                You’ll get notified by email when accepted. Check
                your&nbsp;spams!
              </div>
            </div>
          </form>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <wistia-player
            media-id="r3a1nbdrja"
            seo="false"
            class="video-player"
          ></wistia-player>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscribeForm",
  data() {
    return {
      email: "",
      error: "",
      loading: false,
      success: false,
    };
  },
  methods: {
    onSubmit: function () {
      this.loading = true;
      this.success = false;
      this.error = "";
      this.$store.getters.get_db
        .collection("waitlist_form")
        .add({
          email: this.email,
          dt_added: new Date(),
        })
        .then(() => {
          this.error = "";
          this.success = true;
          this.loading = false;
        })
        .catch((error) => {
          this.error = error;
          this.success = false;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";

wistia-player[media-id="tyykqxc8bx"]:not(:defined) {
  background: center / contain no-repeat
    url("https://fast.wistia.com/embed/medias/r3a1nbdrja/swatch");
  display: block;
  filter: blur(5px);
  padding-top: 56.25%;
}

.video-player {
  max-width: 800px;
  margin: auto;
  margin-bottom: 70px;
}

.waitlist {
  background: url("../assets/backgrounds/blur.png") no-repeat center;
  background-size: cover;
  margin-top: -56px;
  padding-top: 56px;
}

@media (min-width: $mdc-tablet) {
  .waitlist {
    margin-top: -64px;
    padding-top: 64px;
  }
}

.waitlist__form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // min-height: calc(100vh - 350px);
  text-align: center;
  padding: 70px 0 50px 0;
}

.waitlist-form {
  @include theme-glass-effect;
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .waitlist-form__top {
    margin-bottom: 30px;
  }
  .waitlist-form__bottom {
    width: 100%;
  }

  .waitlist-form__title {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 8px;
  }

  .waitlist-form__content {
    display: flex;
    justify-content: center;

    .waitlist-form__input {
      width: 100%;
      max-width: 400px;
      height: 30px;
      border-radius: 30px;
      padding-left: 12px;
      border: 1px solid rgba($theme-pink, 0.5);
      background: transparent;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      color: white;

      &:focus {
        border: 1px solid rgba($theme-pink, 1);
        outline: none;
      }

      &:required {
        box-shadow: none;
      }
    }

    input.waitlist-form__input {
      color: white;
    }

    .waitlist-form__button {
      display: flex;
      align-items: center;
      height: 32px;
      border-radius: 100px;
      border: none;
      margin-left: 10px;
      @include theme-pink-gradient(45deg);

      svg {
        fill: white;
        height: 20px;
      }
    }
  }

  .waitlist-form__success {
    color: $theme-blue;
    margin-top: 8px;
    font-weight: bolder;
  }

  .waitlist-form__error {
    color: $theme-pink;
    margin-top: 8px;
    font-weight: bolder;
  }

  .waitlist-form__subtitle-2 {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 4px;
  }
}
</style>
